<template>
	<div class="concent">
		<Header></Header>
		<div class="warper">
			<div class="header">
				<el-menu :default-active="$route.path" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" router>
					<sidebar-item :tableList="menuList" />
				</el-menu>
				<!-- <div class="w-bottom"></div> -->
			</div>
			<div class="main">
				<router-view />
			</div>
			<div class="footer">
				<!-- 尾部 -->
				<Footer></Footer>
			</div>
		</div>
	</div>
</template>


<script>
import Header from "./header.vue";
import Footer from "./footer.vue";
import SidebarItem from './sidevarItem.vue'
import { Menu, EditPen, Message, ArrowRight } from '@element-plus/icons-vue'
import { userInfo } from "api/apis.js";

export default {
	name: 'home',
	props: {
		// msg: String
	},
	components: {
		Header, Footer, Menu, EditPen, Message, ArrowRight, SidebarItem
	},
	data() {
		return {
			ArrowRight,
			indexBreadcrumbs: [],
			arr: '',
			menuList: [
				{
					path: '/Master',
					name: 'Master',
					title: '报名管理首页',
				},
				{
					path: 'page',
					name: 'Teachers',
					title: '报名管理',
					children: [
						{
							path: '/datangCupNational',
							name: 'datangCupNational',
							title: '大学生报名',
							children: [
								{
									path: '/Teachers',
									name: 'Teachers',
									title: '省赛',
								},
								{
									path: '/NationalFinals',
									name: 'NationalFinals',
									title: '全国总决赛',
								},
								{
									path: '/BeltRoadInvitational',
									name: 'BeltRoadInvitational',
									title: '一带一路邀请赛',
								}
							]
						},
						{
							path: '/doubleDivision',
							name: 'DoubleDivision',
							title: '双师型教师报名',
						},
						{
							path: '/auditInformation',
							name: 'auditInformation',
							title: '审核信息',
						},
						{
							path: '/invoiceInformation',
							name: 'invoiceInformation',
							title: '发票开具',
						},
					]
				},
				{
					path: 'prizeDisplay',
					name: 'prizeDisplay',
					title: '获奖信息管理',
					children:[
						{
							path: '/provincialDwards',
							name: 'ProvincialDwards',
							title: '省赛获奖公示',
						},
						{
							path: '/nationalPrizeAnnouncemen',
							name: 'nationalPrizeAnnouncemen',
							title: '全国赛获奖公示',
						},
						{
							path: '/TeachPrizeAnnouncemen',
							name: 'TeachPrizeAnnouncemen',
							title: '教师赛获奖公示',
						},
					]
				},
				{
					path: 'invigilation',
					name: 'invigilation',
					title: '监考视频上传',
					children:[

					]
				},
				{
					path: '/certificateMailing', //证书邮寄地址
					name: 'CertificateMailing',
					title: '证书邮寄地址登记',
				},
			],
		}
	},
	watch: {
		// '$route'(to,from){
		// 		//监听路由变化设置面包屑
		// 	} 
	},
	mounted() {
		let dataobj = new FormData();
      	dataobj.append("user_id", JSON.parse(localStorage.getItem('UPuser')).user_id);
		userInfo.menu_power(dataobj).then((res) => {
			console.log(res.data);
			this.arr = res.data
			let list = this.menuList
			data(list)
			function data(list){
				list.map((item,index)=>{
					// console.log(item)
					if(item.children){
						data(item.children)
						
					}else {
						// 省赛
						if(item.path == '/Teachers') {
							if(res.data.student_province_apply_sign != true) {
								item.path = ''
							}else {
								item.path = '/Teachers'
							}
							
						}
						//国赛 
						if(item.path == '/NationalFinals') {
							if(res.data.student_country_apply_sign != true) {
								item.path = ''
							}else {
								item.path = '/NationalFinals'
							}
						}

						//一带一路 
						if(item.path == '/BeltRoadInvitational') {
							if(res.data.student_country_apply_sign != true) {
								item.path = ''
							}else {
								item.path = '/BeltRoadInvitational'
							}
						}

						//教师赛 
						if(item.path == '/doubleDivision') {
							if(res.data.teacher_country_apply_sign != true) {
								item.path = ''
							}else {
								item.path = '/doubleDivision'
							}
						}
						return
					}
			})
			}

			// 省赛获奖
			if (this.arr.student_country_disclosure_sign == true) {
			} else {
			}
			// 国赛获奖
			if (this.arr.student_province_disclosure_sign == true) {
			} else {
			}
      });
		
    },
	created() {
	},
	methods: {

	},

}
</script>

<style scoped lang="scss">
:deep(.el-sub-menu__title) {
	border-bottom: solid #ccc 1px
}

:deep(.el-menu-item) {
	border-bottom: solid #ccc 1px
}

.router-link-active {
	text-decoration: none;
}

@import './index.scss';
</style>
