<template>
	<div>
		<div class="h-header">
			<div class="h-head" @click="goHome">
				<img src="@/assets/images/back/logo.png" alt="">
				<div class="txt">
					<p>大唐杯</p>
					<span>报名管理系统</span>
				</div>
			</div>
			<div class="h-main" >
				<div class="user">

					<span>{{this.$store.state.User.real_name}}</span>
					&nbsp;
					&nbsp;
					<!-- <img src="@/assets/images/back/user.png" alt=""> -->
					<img v-if="gender == 1" src="@/assets/images/back/manI.png" alt="">
					<img v-else src="@/assets/images/back/womanI.png" alt="">
					&nbsp;
					<b class="caret" @click="pull_down"></b>
					<el-collapse-transition>
						<div class="changeBox" v-show="pull_show ">
						<p class="changePassword" @click="brief">修改密码</p>
					</div>
					</el-collapse-transition>
				</div>
			</div>
		</div>


		<el-dialog
			title="修改密码"
			width="60%"
			v-model="dialogVisible"
			:before-close="handleClose">
			<div class="content">
            <p class="contenTitle">修改密码</p>
            <p class="cross"></p>
            <p style="text-align: center;">
				<!-- <span>请输入密码:</span> -->
                <el-input type="text" v-model="oldPassword" class="contentInput" placeholder="请输入密码" onfocus="this.placeholder=''" onblur="this.placeholder='请输入密码'" show-password></el-input>
            </p>
            <p style="text-align: center;">
				<!-- <span>请设置新密码:</span> -->
                <el-input type="text" v-model="newPassword" class="contentInput" placeholder="请设置新密码" onfocus="this.placeholder=''" onblur="this.placeholder='请设置新密码'" show-password></el-input>
            </p>
            <p style="text-align: center;">
				<!-- <span>请再次输入新密码:</span> -->

                <el-input type="text" v-model="confirmPassword" class="contentInput" placeholder="请再次输入新密码" onfocus="this.placeholder=''" onblur="this.placeholder='请再次输入新密码'" show-password></el-input>
            </p>
            <p style="text-align: center;">
                <el-button type="info" id="contentBut" @click="password">修改密码</el-button>
            </p>
        </div>
		</el-dialog>
	</div>
</template>


<script>
	import {getLogin} from "api/apis.js"
	export default {
		data() {
			return {
				pull_show:false,
				dialogVisible: false,
				oldPassword:"",
				newPassword:"",
				confirmPassword:"",
				gender:'1'
			}
		},
		mounted(){
			this.getGender();

		},
		methods:{
			goHome() {
				this.$router.push('/HomePage')
			},
			pull_down(){
				this.pull_show = !this.pull_show
			},
			brief(){
				this.dialogVisible = true
				console.log(this.dialogVisible)
			},
			password(){
				let  user_id = this.$store.state.User.user_id
				console.log( this.$store.state.User.user_id)
				if(this.newPassword != this.confirmPassword){
					this.$message.error('两次密码输入不一致！');
					return false
				}
				if(this.confirmPassword.length<6){
					this.$message.error("密码不能少于6位数");
					return false
				}
				if(this.newPassword.length<6){
					this.$message.error("密码不能少于6位数");
					return false
				}

				let dataobj = new FormData();
				dataobj.append('user_id', user_id); //user_id
				dataobj.append('old_password', this.oldPassword); //旧密码
				dataobj.append('new_password', this.newPassword); //新密码

				getLogin.getNewsPassword(dataobj).then(res => {
					if (res.code == 200) {
						console.log(res)
						this.$message({
						message: '恭喜您，修改成功',
						type: 'success'
					});
					this.dialogVisible = false
					}else{
						this.$message.error('原密码输入错误');
					}
				})
			},
			getGender(){
				let id_num =  this.$store.state.User.id_num;
				if(id_num && id_num.length == 18){
					let sexCode = id_num.charAt(16);
					console.log(sexCode);
					this.gender = parseInt(sexCode, 10) % 2 === 0 ? '2' : '1';
				}
			}
		}
	}
</script>

<style scoped lang="scss">
/deep/ .el-dialog__header {
  background: #d7edfb !important;
  margin-right: 0 !important;
}
:deep(.el-input__wrapper) {
    box-shadow: 0 0 0 0px var(--el-input-border-color, var(--el-border-color)) inset;
    cursor: default;
    .el-input__inner {
      cursor: default !important;
    }
  }
	@import './index.scss';
</style>
