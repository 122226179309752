<template>
	<div>
		<div class="f-footer">
			<div class="f-main">
				<div class="box">
					<h2>大唐杯竞赛平台快速指南</h2>
					<dl>
						<dt>
							<el-icon color="#409EFF">
								<ChatLineSquare />
							</el-icon>
							1.如何进行报名？是否可以多次进行报名？
						</dt>
						<dd>
							1.如何进行报名？点击左侧的报名管理或报名信息上传即可进入报名页面，根据赛道划分，报名分为信息通信工程实践赛和产教融合5G+创新应用设计赛， 点击下载报名模板，按照报名规范填写完成后点击上传报名模板即可进入缴费页面， 一般在缴费完成后会有1-2个工作日的审核时间，审核完毕后即可在已审核信息中看到报名成功的选手。 完成一次报名后可重复上述步骤进行多次报名或补充报名。
						</dd>
					</dl>
					<!-- <dl>
						<dt>
							<el-icon color="#409EFF">
								<ChatLineSquare />
							</el-icon>
							2.是否可以修改报名信息？
						</dt>
						<dd>
							报名信息在审核完成后不能再次修改，如参赛选手因不可抗因素无法参赛，
							请联系赛事对应区域负责老师说明情况后，报备大赛组委会进行修改。
						</dd>
					</dl> -->
					<dl>
						<dt>
							<el-icon color="#409EFF">
								<ChatLineSquare  />
							</el-icon>
							2.如何参加大赛培训？
						</dt>
						<dd>
							报名成功后，大赛官方培训平台-学唐平台会在1-2个工作日内对参赛选手开通培训账号，届时将发送大赛培训邮件与账号密码至报名时预留的邮箱，参赛选手收到邮件后即可登录平台进行学习。并随着赛程的进展，后续还会有直播讲解，仿真练习等培训项目，可关注官网或官方公众号通知。
						</dd>
					</dl>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
import {ChatLineSquare } from '@element-plus/icons-vue'
export default {
  name: 'master',
  props: {
    // msg: String
  },
  components: {
    ChatLineSquare
  },
  data(){
    return {
		// Search:Search,
		time:new Date()
    }
  },
  methods:{
	  
  },
}
</script>

<style scoped lang="scss">
	@import './index.scss';
</style>
