<template>
    <div>
        <el-icon v-if="menu.title === '报名管理'">
            <EditPen />
        </el-icon>
        <el-icon v-if="menu.title === '大学生报名'">
            <School />
        </el-icon>
        <el-icon v-if="menu.title === '报名管理首页'">
            <House />
        </el-icon>
        <el-icon v-if="menu.title === '审核信息'">
            <ChatLineSquare />
        </el-icon>
        <el-icon v-if="menu.title === '双师型教师报名'">
            <SetUp />
        </el-icon>
        <el-icon v-if="menu.title === '发票开具'">
            <DocumentCopy />
        </el-icon>
        <el-icon v-if="menu.title === '省赛' || menu.title === '全国总决赛' || menu.title === '一带一路邀请赛'">
            <DocumentAdd />
        </el-icon>
        <el-icon v-if="menu.title === '获奖信息管理'">
            <Medal />
        </el-icon>
        <el-icon v-if="menu.title === '省赛获奖公示' || menu.title === '全国赛获奖公示' || menu.title === '教师赛获奖公示'">
            <Trophy />
        </el-icon>
        <el-icon v-if="menu.title === '监考视频上传' ">
            <VideoPlay />
        </el-icon>
        <el-icon v-if="menu.title === '证书邮寄地址登记' ">
            <Location />
        </el-icon>
    </div>
</template>
<script>
import { 
    EditPen, 
    School, 
    House, 
    ChatLineSquare, 
    SetUp,
     DocumentAdd,
     Medal,
     Trophy,
  VideoPlay,
  Location,
  DocumentCopy
      
    } from '@element-plus/icons-vue';
export default {
    components: { 
        EditPen, 
        School, 
        House, 
        ChatLineSquare, 
        SetUp, 
        DocumentAdd ,
        Medal,
        Trophy,
        VideoPlay,
        Location,
        DocumentCopy
    },
    props: ['menu'],
    data() {
        return {}
    }
}
</script>