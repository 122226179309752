<template>
    <div class="navMenu">
        <label v-for="(menu, index) in tableList" :key="index">
            <el-sub-menu :index="menu.path" v-if="menu.children && menu.children.length > 0" :key="menu.path">
                <template #title>
                    <sidevar-item-icon :menu="menu" />
                    <span>{{ menu.title }}</span>
                </template>
                <label>
                    <nav-menu :tableList="menu.children"></nav-menu>
                </label>
            </el-sub-menu>
            <el-menu-item v-else :index="menu.path" :style="{ 'color': menu.path ? '' : '#BBBBBB' }">
                <template #title v-if="menu.path">
                    <sidevar-item-icon :menu="menu" />
                    <span>{{ menu.title }}</span>
                </template>
                <template #title v-else>
                    <el-tooltip class="item" content="未在开放时段" placement="top-start">
                        <div style="display: flex;width:100%">
                            <sidevar-item-icon :menu="menu" />
                            <span>{{ menu.title }}</span>
                        </div>
                    </el-tooltip>
                </template>
            </el-menu-item>
        </label>
    </div>
</template>

<script>
import sidevarItemIcon from './sidevarItemIcon.vue';
export default {
    name: 'NavMenu',
    components: { sidevarItemIcon },
    props: ['tableList'],
    data() {
        return {
            menuList: []
        }
    },
    created() {
        console.log('123')
        console.log(this.tableList)
        // this.menuList = JSON.stringify(this.tableList);
        // console.log('this.menuListthis.menuList',this.menuList);
    },
    methods: {
        handleSelect(key, keyPath) {
            console.log('1212')
            console.log(key, keyPath)
        }
    }
}
</script>

<style scoped lang="scss"></style>